<template>
    <div class='' v-loading='loading'>
        <nf-breadcrumb :breadList="breadList"></nf-breadcrumb>
        <div v-if='data.length>0'>
            <div class='title clearfix'>
                <span class='fl'>实验室共享</span>
                <router-link tag='button' class='fr' :to='{path:"/downLoad"}'>我也要发布</router-link>
                <!-- <button class='fr'>我也要发布</button> -->
            </div>
            <div>
                <div class='list flex'>
                    <div v-for='(item, index) in data' class='listItem' :key='index' @click='onGoDetaile(item.id)'>
                        <el-image
                            style="width: 100%; height: 210px"
                            :src="item.imageUrl || 'http://hnnmy.oss-cn-shanghai.aliyuncs.com/file/nanfan/trademarket/2019-12-05/9d28408b-c75f-49e3-a8e9-0fc97dbf75a2.png'"
                            fit="cover"></el-image>
                        <div class='name ellipsis'>{{item.name?item.name:"暂无数据"}}</div>
                        <div class='ellipsis'>地址：{{item.address?item.address:"暂无数据"}}</div>
                        <div class='ellipsis'>服务范围：{{item.serviceScope?item.serviceScope:"暂无数据"}}</div>
                    </div>
                </div>
                <pagination v-if='page.total > param.pageSize' @pageChange="pageChange" :total='page.total' :currentPage='page.currentPage' :pageSize='page.pageSize'></pagination>
            </div>
        </div>
        <div v-else class='dataNull'>
            <no-data></no-data>
        </div>

    </div>
</template>
<script>
import pagination from '@/component/pagination/index.vue';
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';
import noData from '@/component/noData/index';
import Vue from 'vue';
import { Image } from 'element-ui';
Vue.use(Image);
export default {
    components: {
        nfBreadcrumb,
        pagination,
        noData
    },
    data() {
        return {

          startTime: null,
          pageName: "实验室共享(labShare.vue)",
             data: [],
             page: {
                 total: 0,
                 currentPage: 1,
                 pageSize: 15
             },
            param: {
                cityType: '',
                keyword: '',
                pageNum: 1,
                pageSize: 15
             },
             breadList: [{
                name: '资源共享'
            }, {
                name: '实验室共享'
            }],
            loading: true
        };
    },
  beforeCreate() {
    this.startTime = performance.now();
    let this_ = this;
    //1.页面停留时间
    window.addEventListener('beforeunload', function() {
      let endTime = performance.now();
      let stayTime = ((endTime - this_.startTime)/1000).toFixed(1);
      console.log("stayTime", stayTime);

      window.SZHN_FIRE_BEAR?.trackEvent({
        key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
        segmentation: {
          stayTime: stayTime,
        }
      })
    });
    //3.页面加载时间
    window.addEventListener('load', function() {
      let endTime = performance.now();
      let loadTime = ((endTime - this_.startTime)/1000).toFixed(1);
      console.log("loadTime", loadTime);
      window.SZHN_FIRE_BEAR?.trackEvent({
        key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
        segmentation: {
          loadTime: loadTime,
        }
      })
    });
  },
  created() {
    //2.用户浏览量
    window.SZHN_FIRE_BEAR?.trackEvent({
      key: this.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
      segmentation: {
        pageViews: 1,
      }
    })
    //4.网络请求状态
    this.trackNetworkRequest("https://nfggy.com");
  },

  mounted() {
        this.initData();
    },
    methods: {
      trackNetworkRequest(url) {
        let requestStartTime = performance.now();
        let this_ = this;

        fetch(url)
            .then(response => {
              let requestEndTime = performance.now();
              let requestTime = ((requestEndTime - requestStartTime)/1000).toFixed(3);
              console.log("requestSuccessTime", requestTime);
              window.SZHN_FIRE_BEAR?.trackEvent({
                key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
                segmentation: {
                  requestTime: requestTime,
                  requestStatu: "success",
                  requestResult: response.toString()
                }
              })
            })
            .catch(error=>{
              let requestEndTime = performance.now();
              let requestTime = ((requestEndTime - requestStartTime)/1000).toFixed(3);
              console.log("requestErrorTime", requestTime);

              window.SZHN_FIRE_BEAR?.trackEvent({
                key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
                segmentation: {
                  requestTime: requestTime,
                  requestStatu: "error",
                  requestResult: error.toString()
                }
              })
            })
      },
      pageChange (item) {
            this.page.currentPage = item.page_index;
            this.page.pageSize = item.page_limit;
            this.initData(); //改变页码，重新渲染页面
        },
        async initData() {
            this.loading = true;
            this.param.pageNum = this.page.currentPage;
            const api = this.$fetchApi.laboratory.api;
            const data = await this.$fetchData.fetchPost(this.param, api, "json");
            this.loading = false;
            if (data.code === '200' && data.result) {
                this.data = data.result.list;
                if (this.page.currentPage === 1) {
                    this.page.total = data.result.total;
                }
            }
        },
        onGoDetaile(id) {
            this.$router.push({
                path: '/labShare/labDetaile',
                query: {
                    id
                }
            });
        }
    }
};
</script>
<style lang="less" scoped>
    @import './common.less';
    .dataNull{
        margin:100px auto;
        width:1200px;
    }
    .title{
        button{
            cursor: pointer;
        }
    }
</style>
